import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';
import tippy from 'tippy.js';

export default class extends Controller {
  static debounces = [
    'showTooltip',
  ];

  static values = {
    interactive: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'top-start',
    },
  };

  connect() {
    useDebounce(this);

    this.tippy = tippy(this.element, {
      content: 'Chargement...',
      allowHTML: true,
      interactive: this.interactiveValue,
      appendTo: () => (this.interactiveValue ? document.body : 'parent'),
      theme: 'light-border',
      placement: this.placementValue,
      onShow(instance) {
        // Code here is executed every time the tippy shows
        const { url } = instance.reference.dataset;
        fetch(url)
          .then((response) => response.text())
          .then((html) => {
            instance.setContent(html);
          });
      },
    });
  }

  showTooltip(e) {
    e.preventDefault();
  }
}
