import { Controller } from '@hotwired/stimulus';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['codeConnectionForm', 'dropzone', 'cartoapCode'];

  connect() {
    this.dropzoneTargets.forEach((elem) => {
      elem.addEventListener('dragenter', this.handleDragEnter);
      elem.addEventListener('dragleave', this.handleDragLeave);

      elem.addEventListener('dragover', this.handleDragEnter);

      elem.addEventListener('drop', this.handleDrop.bind(this));
    });
  }

  handleDragEnter(event) {
    event.preventDefault();
    // console.log('drag enter', event);
    const parentDropzone = event.target.closest('[data-account-code-id]');

    parentDropzone.classList.add('dropzone--allowed');
  }

  handleDragLeave(event) {
    event.preventDefault();
    // console.log('drag leave', event);
    const parentDropzone = event.target.closest('[data-account-code-id]');

    parentDropzone.classList.remove('dropzone--allowed');
  }

  handleDrop(event) {
    event.preventDefault();
    const cartoapCodeId = event.dataTransfer.getData('text');
    const parentDropzone = event.target.closest('[data-account-code-id]');

    parentDropzone.classList.remove('dropzone--allowed');

    this.createConnection(parentDropzone, cartoapCodeId);
  }

  createConnection(dropzoneElement, cartoapCodeId) {
    const form = dropzoneElement.querySelector('form');
    const cartoapInput = dropzoneElement.querySelector('[data-cartoap-code-input');

    cartoapInput.value = cartoapCodeId;

    form.requestSubmit();
  }
}
