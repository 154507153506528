import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';
import { createPopper } from '@popperjs/core';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['input', 'form', 'results', 'resultsList', 'result', 'valueField', 'currentSourceLabel'];

  static debounces = [
    'submit',
    {
      wait: 300,
    },
  ];

  static values = {
    url: String,
    placement: {
      type: String,
      default: 'bottom-end',
    },
    offset: {
      type: Array,
      default: [0, 8],
    },
    multiSources: { // adds a dropdown to select a source
      type: Boolean,
      default: false,
    },
    // When using an URL instead of a form, use this to name the attribute we need to search on
    attributeName: String,
  };

  connect() {
    useDebounce(this);

    this.inputTarget.addEventListener('keyup', (event) => {
      if (!this.hasResultsListTarget || !this.hasResultTarget) this.submit();

      if (this.hasResultsListTarget && this.hasResultTarget) {
        switch (event.which) {
          case 13:
            this.pick();
            break;
          case 40:
            this.next();
            break;
          case 38:
            this.previous();
            break;
          default:
            this.submit();
        }
      }
    });

    this.inputTarget.addEventListener('focusout', (event) => {
      if (this.hasResultsListTarget) {
        if (!this.element.contains(event.relatedTarget)) {
          this.hideResultsList();
        } else { // Click inside the liste
          setTimeout(() => {
            this.hideResultsList();
          }, 200);
        }
      }
    });

    console.log('this has results target', this.hasResultsTarget);
    if (this.hasResultsTarget) {
      this.popperInstance = createPopper(
        this.inputTarget,
        this.resultsTarget,
        {
          placement: this.placementValue,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: this.offsetValue,
              },
            },
          ],
        },
      );
    }

    this.inputTarget.addEventListener('focus', () => {
      if (this.hasResultsListTarget) {
        this.popperInstance.update();
        this.resultsListTarget.classList.add('show');
      }
    });
  }

  changeSource(event) {
    event.preventDefault();
    const { sourceName, sourceUrl } = event.params;

    this.currentSourceLabelTarget.innerText = sourceName;

    console.log('url', sourceUrl);
    if (this.hasFormTarget) {
      this.formTarget.action = sourceUrl;
    }
  }

  hideResultsList() {
    this.resultsListTarget.classList.remove('show');
  }

  select(e) {
    e.target.select();
  }

  submit() {
    if (this.hasFormTarget) {
      this.formTarget.requestSubmit();
    } else if (this.hasUrlValue) {
      const searchParams = this.getSearchParams();

      fetch(`${this.urlWithoutExistingParams}?${searchParams}`, {
        headers: {
          Accept: 'text/vnd.turbo-stream.html',
        },
      }).then((r) => r.text())
        .then((html) => {
          Turbo.renderStreamMessage(html);
        });
    }
  }

  resultsListTargetConnected() {
    if (this.hasResultTarget) {
      this.selectFirstResult();
    }
  }

  getSearchParams() {
    // Preserve eventually existing params in this.urlValue
    const [, paramString] = this.urlValue.split('?');
    const params = new URLSearchParams(paramString);
    params.append(this.attributeNameValue, this.inputTarget.value);

    return params;
  }

  selectFirstResult() {
    this.selectedResultIndex = 0;
  }

  next() {
    if (this.selectedResultIndex === this.resultTargets.length - 1) {
      this.selectedResultIndex = 0;
    } else {
      this.selectedResultIndex += 1;
    }
  }

  previous() {
    const currentIndex = this.selectedResultIndex;
    if (currentIndex - 1 < 0) {
      this.selectedResultIndex = this.resultTargets.length - 1;
    } else {
      this.selectedResultIndex -= 1;
    }
  }

  pick() {
    console.log('picking');
    this.resultTargets[this.selectedResultIndex].click();
  }

  setValue(event) {
    event.preventDefault();
    event.stopPropagation();
    const { userDisplayedValue, actualValue } = event.params;

    this.inputTarget.value = userDisplayedValue;
    this.valueFieldTarget.value = actualValue;

    this.hideResultsList();
  }

  set selectedResultIndex(index) {
    if (typeof this.selectedResultIndex !== 'undefined') {
      this.resultTargets[this.selectedResultIndex].classList.remove('js-active');
    }

    this._selectedResultIndex = index;

    this.resultTargets[index].classList.add('js-active');
  }

  get selectedResultIndex() {
    return this._selectedResultIndex;
  }

  get urlWithoutExistingParams() {
    const [basePath] = this.urlValue.split('?');
    return basePath;
  }
}
